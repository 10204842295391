<template>
  <div style="background: white">
    <Loading :loadingCounter="loadingCounter" />
    <loading-spinner />
    <error-message-dialog />
    <v-container fluid id="sub-bar">
      <v-col>
        <div id="lbl-screen-name">{{ screenName }}</div>
      </v-col>
    </v-container>
    <v-container fluid>
      <v-row class="search-row">
        <!-- 取引先 -->
        <c-client-input
          v-model="commonForm.supplierSelected"
          readonly
          width="400px"
          class="first-search-item"
        />
        <!-- 閉じるボタン -->
        <div class="btn-area" style="float: right; padding-left: 1100px">
          <v-btn @click="close()" class="other-btn">{{ $t("btn.btn_close") }} </v-btn>
        </div>
      </v-row>
    </v-container>
    <!-- データテーブル -->
    <v-container fluid>
      <v-data-table
        id="listData"
        :headers="headerItems"
        :items="inputList"
        :fixed-header="true"
        :options.sync="sortOptions"
        disable-pagination
        :hide-default-footer="true"
        height="500px"
      >
        <!-- キー情報 -->
        <template v-slot:[`item.key`]="{ item }">
          <div class="listNo-style">{{ item.key }}</div>
        </template>

        <!--エラーデータ -->
        <template v-slot:[`item.errorData`]="{ item }">
          <div class="listNo-style">{{ item.errorData }}</div>
        </template>
      </v-data-table>
    </v-container>
  </div>
</template>

<script>
import { i18n } from "../../lang/lang.js";
import Loading from "@/components/loading";
import { appConfig } from "../../assets/scripts/js/AppConfig";
import commonMixin from "@/mixins/CommonMixin";

export default {
  name: appConfig.SCREEN_ID.P_STK_102,
  components: {
    Loading,
  },
  mixins: [commonMixin],
  props: {
    entity: { type: Object, default: null },
    detailDialogData: {},
    hoge1: {
      default: "",
    },
    isOpenDetailDialog: { type: Boolean, default: false },
  },
  data: () => ({
    // ソート
    sortOptions: {},
    // ローディング画面表示フラグ
    loadingCounter: 0,
    commonForm: {
      // 取引先
      supplierSelected: "",
    },
    //画面名
    screenName: "",
    stockList: [],
    headerItems: [
      // No
      {
        text: i18n.tc("label.lbl_No"),
        width: "5%",
        value: "No",
        sortable: true,
        align: "right",
      },
      // キー情報
      {
        text: "",
        value: "key",
        width: "20%",
        sortable: true,
        align: "left",
      },
      // 行
      {
        text: i18n.tc("label.lbl_rows"),
        value: "rows",
        width: "5%",
        align: "right",
        sortable: true,
      },
      // 列
      {
        text: i18n.tc("label.lbl_column"),
        value: "column",
        width: "5%",
        align: "right",
        sortable: true,
      },
      // エラー内容
      {
        text: i18n.tc("label.lbl_errorContent"),
        value: "errorContent",
        width: "20%",
        align: "left",
        sortable: true,
      },
      // エラーデータ
      {
        text: i18n.tc("label.lbl_errorData"),
        value: "errorData",
        width: "20%",
        align: "left",
        sortable: true,
      },
    ],
    // 明細リスト
    inputList: [],
  }),
  methods: {
    /**
     * 初期起動：画面を開いた際に渡された値を設定します。
     */
    makeData() {
      this.inputList = [];
      for (var i = 0; i < this.entity.list.length; i++) {
        this.inputList.push({
          No: "",
          key: this.entity.list[i].listNo,
          rows: this.entity.list[i].row,
          column: this.entity.list[i].column,
          errorContent: this.entity.list[i].message,
          errorData: this.entity.list[i].value,
        });
      }
      // 連番
      for (var n = 0; n < this.inputList.length; n++) {
        this.inputList[n].No = n + 1;
      }
    },
    /**
     * 初期起動：画面を開いた際に渡された値を設定します。
     */
    init() {
      this.suppliersSelectedName = this.entity.suppliersSelectedName;
      this.screenName = this.entity.screenName;
      this.headerItems[1].text = this.entity.uniqueName;
      this.commonForm.supplierSelected = this.entity.suppliersSelected;
      this.makeData();
    },
    //ダイアログを閉じる
    close() {
      this.$emit("closePopup");
      this.$emit("update:isOpenDetailDialog", false);
      this.inputList = [];
    },
  },
  watch: {
    entity: {
      handler(newValue, oldValue) {
        console.info("handler", newValue, oldValue);
      },
    },
    // eslint-disable-next-line no-unused-vars
    isOpenDetailDialog: function (newValue) {
      if (newValue) {
        this.init();
      }
    },
    sortOptions: {
      handler(items) {
        if (items.sortBy.length < 1) {
          this.sortItem = "";
        } else {
          var sortBy = items.sortBy[0];
          var ascdesc = items.sortDesc[0] ? "desc" : "asc";
          this.sortItem = sortBy + " " + ascdesc;
          if (sortBy == "No") {
            this.inputList.sort((a, b) => {
              if (ascdesc == "asc") {
                return a.No - b.No;
              } else {
                return b.No - a.No;
              }
            });
          } else if (sortBy == "slipNo") {
            this.inputList.sort((a, b) => {
              if (ascdesc == "asc") {
                if (a.slipNo < b.slipNo) {
                  return -1;
                } else if (a.slipNo > b.slipNo) {
                  return 1;
                } else {
                  return 0;
                }
              } else {
                if (a.slipNo > b.slipNo) {
                  return -1;
                } else if (a.slipNo < b.slipNo) {
                  return 1;
                } else {
                  return 0;
                }
              }
            });
          } else if (sortBy == "rows") {
            this.inputList.sort((a, b) => {
              if (ascdesc == "asc") {
                return a.rows - b.rows;
              } else {
                return b.rows - a.rows;
              }
            });
          } else if (sortBy == "column") {
            this.inputList.sort((a, b) => {
              if (ascdesc == "asc") {
                return a.column - b.column;
              } else {
                return b.column - a.column;
              }
            });
          } else if (sortBy == "errorContent") {
            this.inputList.sort((a, b) => {
              if (ascdesc == "asc") {
                if (a.errorContent < b.errorContent) {
                  return -1;
                } else if (a.errorContent > b.errorContent) {
                  return 1;
                } else {
                  return 0;
                }
              } else {
                if (a.errorContent > b.errorContent) {
                  return -1;
                } else if (a.errorContent < b.errorContent) {
                  return 1;
                } else {
                  return 0;
                }
              }
            });
          } else if (sortBy == "errorData") {
            this.inputList.sort((a, b) => {
              if (ascdesc == "asc") {
                if (a.errorData < b.errorData) {
                  return -1;
                } else if (a.errorData > b.errorData) {
                  return 1;
                } else {
                  return 0;
                }
              } else {
                if (a.errorData > b.errorData) {
                  return -1;
                } else if (a.errorData < b.errorData) {
                  return 1;
                } else {
                  return 0;
                }
              }
            });
          }
          return this.inputList;
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.init();
  },
};
</script>
<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
#sub-bar {
  padding: none;
  height: 3rem;
}

.text-overflow {
  // v-tooltip
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
